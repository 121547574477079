<template>
	<div>
		<div class="goods_spu">
			<div class="purchaseupadd">
				<div class="global-search-box addglobal">
					<div class="search-content">
						<div class="search-item">
							<el-input v-model="note_name" :placeholder="spusearchtext == 1 ? '请输入商品SPU编号' : '请输入公共名称'" ref="spusearchtexted">
								<template slot="prepend">
									<el-select v-model="spusearchtext" @change="spuselect">
										<el-option v-for="(item, index) in natureList" :key="item.value" :label="item.label"
											:value="item.value"></el-option>
									</el-select>
								</template>
							</el-input>
						</div>
					</div>
					<div class="serach-buttom-button">
						<el-button type="primary" @click="spusearch">搜索</el-button>
					</div>
				</div>
			</div>
			<div class="good_spubut">
				<div class="cation_container">
				    <div class="goods_table">
				        <el-table :data="tableData" style="width: 100%">
				            <el-table-column prop="spu_code" width="200" label="SPU编号"></el-table-column>
				            <el-table-column prop="spu_name" min-width="80%" label="公共名称">
				            </el-table-column>
				            <el-table-column label="操作" fixed="right" width="200">
				                <template slot-scope="scope">
				                    <div class="caozuo spu_caozuo">
				                        <button @click="spuedit(scope.row.id)" v-if="hasPerm(['goods.spu.update'])">编辑</button>
				                        <button @click="lookspu(scope.row.id)" v-if="hasPerm(['goods.spu.show'])">查看</button>
				                    </div>
				                </template>
				            </el-table-column>
				
				            <template slot="empty">
				                <div class="empty_data">
				                    <p>暂无数据</p>
				                </div>
				            </template>
				        </el-table>
				    </div>
				    <div class="table_foot">
						<div class="foot_left">
						</div>
						<div class="main_pages">
							<el-pagination @size-change="handleSizeChange" hide-on-single-page
								@current-change="handleCurrentChange" :current-page.sync="currentPage"
								:page-sizes="[10, 20, 30, 40]" :page-size="eachPage"
								layout="sizes, prev, pager, next, jumper" :pager-count="5" :total="total_entry">
							</el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			spusearchtext: "1",
			note_name: '',
			tableData: [], //表格数据
			currentPage: 1,
			eachPage: 10,
			total_entry: 0,
			selectval: '',
			natureList: [{
				label: 'SPU编号',
				value: '1',
			},
			{
				label: '公共名称',
				value: '2',
			}
			],
		};
	},
	components: {},
	computed: {
	},
	created() {

	},
	mounted() {
		this.spulistapi();
	},
	methods: {

		spulistapi() {
			// this.$loading();
			let data = {
				search: [{
					key: 'spu_code',
					value: this.note_name,
					op: "search"
				}],
				order: [{
					key: "id",
					value: "desc"
				}],
				page: this.currentPage,
				limit: this.eachPage
			};
			if (this.selectval == 1) {
				data.search[0].key = "spu_code";
			} else if (this.selectval == 2) {
				data.search[0].key = "spu_name";
			}
			this.$get(this.$apis.spuList, data).then(res => {
				if (res.code == 200) {
					// this.$loading().close();
					// console.log(res)
					this.tableData = res.data.list;
					this.total_entry = res.data.total;
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// console.log(err)
				this.common.message(this, err.message);
			});
		},

		spuedit(id) {
			this.$router.push({
				name: "goods_bindspu",
				params: {
					id: id
				}
			});
		},
		lookspu(id) {
			this.$router.push({
				path: '/goods/goods_lookspu',
				query: {
					id: id
				}
			});
		},
		handleSizeChange(val) {
			this.eachPage = val;
			this.spulistapi();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			this.common.backTop();
			this.spulistapi();
		},
		spuselect(val) {
			this.selectval = val;
		},
		// 搜索
		spusearch() {
			let searchtext = this.$refs.spusearchtexted.value;
			this.note_name = searchtext;
			this.currentPage = 1;
			this.spulistapi();
		},
	}
};
</script>

<style scoped>
.orderfirst {
	width: 324px;
}

.orderfirstleft {
	width: 100px;
	float: left;
}

.orderfirstinput {
	width: 224px;
	float: left;
}

.orderfirstinput input {
	display: block;
	outline: none;
	width: 100%;
	height: 40px;
	border-radius: 0 4px 4px 0;
	border: 1px solid rgba(0, 0, 0, .1);
	box-sizing: border-box;
	padding: 0 12px;
	font-size: 14px;
	color: #333;
}

.goods_spu {
	width: 100%;
}

.table_foot {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.purchaseupadd {
	width: 100%;
	border-radius: 4px;
	margin-bottom: 20px;
	background: #fff;
	padding: 20px;
	box-sizing: border-box;
	margin-top: 0;
}
.purchaseupadd .global-search-box .serach-buttom-button{justify-content: flex-start;}


.good_spubut {
	width: 100%;
	border-radius: 4px;
	padding: 20px;
	box-sizing: border-box;
	background: #fff;
}

.goods_table table th,
table td {
	border: none;
}

.goods_table .el-table .el-table__header-wrapper .el-table__header thead tr th {
	background-color: #f9f9fc;
}

.goods_table .el-table .el-table__header-wrapper {
	background-color: #f9f9fc;
}

.goods_table .el-table .el-table__fixed .el-table__fixed-header-wrapper {
	background-color: #f9f9fc;
}

.el-table th.el-table__cell {
	background-color: #f9f9fc;
	font-weight: 600;
	color: #333;
	padding: 8px 0;
}

.caozuo button {
	padding: 0 10px;
	outline: none;
	border: 0;
	border-right: 1px solid var(--fontColor, #fb6638);
	cursor: pointer;
	display: inline-block;
	background: transparent;
	line-height: 1;
	color: var(--fontColor);
}

.caozuo button:last-child {
	border-right: none;
}

.main_pages {
	display: flex;
	align-items: center;
	margin: 10px 0;
}
</style>
